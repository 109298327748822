(function() {

  'use strict';

  const directive = { name: 'hideElementIfSeasonPromoExist' };

  controller.$inject = ['transcludeResolver'];

  function controller(_transcludeResolver){
    function link(scope, element){
      const config = {alias: 'seasonPromoList', params: {category: 'season_promo'}};
      _transcludeResolver.get(config).then((data) => {
        if(data.length) {
          element[0].style.display = 'none';
        }
      })
    }

    return {
      restrict: 'A',
      link
    };
  }

  app.directive( directive.name, controller );
})();
