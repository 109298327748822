import '../../../service/domain/bonus.js';

class BatmanBonusItem {
  static $inject = ['$scope', 'bonus'];
  constructor($scope, bonus) {
    $scope.preloader = false;
    let aliasParam = '';

    $scope.activate = () => {
      if (angular.isNumber($scope.bonus.bonus_id)) {
        $scope.preloader = true;
        bonus.activate($scope.bonus, aliasParam)
          .finally(() => {
            $scope.preloader = false;
          })
      }
    };

    $scope.cancel = () => {
      if (angular.isNumber($scope.bonus.bonus_id)) {
        $scope.preloader = true;
        bonus.cancel($scope.bonus)
          .finally(() => {
            $scope.preloader = false;
          });
      }
    };

    this.$onChanges = () => {
      $scope.bonus = this.bonus;
      $scope.successAlias = this.successAlias || '';

      switch ($scope.bonus.type) {
        case 'casino':
        case 'sport':
          aliasParam = $scope.successAlias;
          break;
        case 'freespin':
          aliasParam = $scope.bonus?.game?.alias;
          break;
        default:
          return aliasParam;
      }
    }
  }
}

new Controller('bonusItem', BatmanBonusItem, {bonus: '<', successAlias: '<'});
(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].forEach((i) => {
    new Batman(`batmanBonusItem${i}`, 'bonusItem');
  });
})();
